@import url('https://fonts.googleapis.com/css?family=Josefin+Sans%3A300%2C400%2C500%2C600%2C700%7CMontserrat%3A300%2C400%2C500%2C600%2C700%7CGreat+Vibes%3A300%2C400%2C500%2C600%2C700&#038;subset=latin-ext&#038;');

// Font Variables
$josefin-sans: 'Josefin Sans', sans-serif;   
$great-vibes: 'Great Vibes', serif;

$white: #fff;
$brand-red: rgb(226, 32, 32);
$brand-gold: #c88a5a;

$gray-lighter: rgb(235, 235, 235);
$gray-light: rgb(195, 195, 195);
$gray: rgb(150, 150, 150); 
$gray-dark: rgb(80, 80, 80);
$gray-darker: rgb(35, 35, 35);

$brand-twitter: rgb(29, 155, 240);

$error: rgb(164, 10, 10);