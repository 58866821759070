@import '../../assets/scss';

#qodef-page-spinner {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10
}

#qodef-page-spinner .qodef-m-inner {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #c88a5a;
    background-color: #fff
}

#qodef-page-spinner .qodef-m-spinner {
    position: relative
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom {
    position: relative;
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--1 {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50%;
    width: 50%;
    z-index: 1;
    -webkit-animation: qode-atom-shrink 4.5s infinite linear;
    animation: qode-atom-shrink 4.5s infinite linear
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--1:before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    height: 100%;
    width: 100%;
    background: currentColor;
    border-radius: 50%
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--2,
#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--3,
#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--4 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--2:before,
#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--3:before,
#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--4:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    margin: 0 auto;
    background: currentColor;
    border-radius: 50%
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--2 {
    -webkit-animation: qode-atom-zindex 1.5s .75s infinite steps(2, end);
    animation: qode-atom-zindex 1.5s .75s infinite steps(2, end)
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--2:before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-animation: qode-atom-postion 1.5s infinite ease, qode-atom-size 1.5s -1.125s infinite ease;
    animation: qode-atom-postion 1.5s infinite ease, qode-atom-size 1.5s -1.125s infinite ease
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--3 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
    -webkit-animation: qode-atom-zindex 1.5s -.25s infinite steps(2, end);
    animation: qode-atom-zindex 1.5s -.25s infinite steps(2, end)
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--3:before {
    -webkit-animation: qode-atom-postion 1.5s -1s infinite ease, qode-atom-size 1.5s -.75s infinite ease;
    animation: qode-atom-postion 1.5s -1s infinite ease, qode-atom-size 1.5s -.75s infinite ease
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--4 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
    -webkit-animation: qode-atom-zindex 1.5s .25s infinite steps(2, end);
    animation: qode-atom-zindex 1.5s .25s infinite steps(2, end)
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--4:before {
    -webkit-animation: qode-atom-postion 1.5s -.5s infinite ease, qode-atom-size 1.5s -125ms infinite ease;
    animation: qode-atom-postion 1.5s -.5s infinite ease, qode-atom-size 1.5s -125ms infinite ease
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock {
    position: relative;
    width: 50px;
    height: 50px;
    margin: -25px 0 0 -25px;
    -webkit-animation: qode-clock 8s infinite steps(8);
    animation: qode-clock 8s infinite steps(8)
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item {
    position: absolute;
    height: 50px;
    width: 50px
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item:before {
    content: '';
    position: absolute;
    height: 18.31501832px;
    width: 18.31501832px;
    background: currentColor;
    border-radius: 50%
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item.qodef-clock--1 {
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
    -webkit-animation: qode-clock-1 1s ease-in infinite;
    animation: qode-clock-1 1s ease-in infinite
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item.qodef-clock--2 {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-animation: qode-clock-2 1s ease-in infinite;
    animation: qode-clock-2 1s ease-in infinite
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item.qodef-clock--3 {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: qode-clock-3 1s ease-in infinite;
    animation: qode-clock-3 1s ease-in infinite
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item.qodef-clock--4 {
    -webkit-animation: qode-clock-4 1s ease-in infinite;
    animation: qode-clock-4 1s ease-in infinite
}

#qodef-page-spinner.qodef-layout--cube .qodef-m-cube {
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    background-color: currentColor;
    -webkit-animation: qode-cube 1.2s infinite ease-in-out;
    animation: qode-cube 1.2s infinite ease-in-out
}

#qodef-page-spinner.qodef-layout--double-pulse .qodef-m-pulses {
    position: relative;
    width: 40px;
    height: 40px;
    margin: -20px 0 0 -20px
}

#qodef-page-spinner.qodef-layout--double-pulse .qodef-m-pulse {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    border-radius: 50%;
    opacity: .6;
    -webkit-animation: qode-double-pulse 2s infinite ease-in-out;
    animation: qode-double-pulse 2s infinite ease-in-out
}

#qodef-page-spinner.qodef-layout--double-pulse .qodef-m-pulse.qodef-pulse--2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-five-rotating-circles {
    position: relative;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item {
    position: absolute;
    width: 100%;
    height: 100%
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--1 .qodef-circle--2 {
    -webkit-animation-delay: calc((600ms) - 1500ms);
    animation-delay: calc((600ms) - 1500ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--1 .qodef-circle--3 {
    -webkit-animation-delay: calc((900ms) - 1500ms);
    animation-delay: calc((900ms) - 1500ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--1 .qodef-circle--4 {
    -webkit-animation-delay: calc((1200ms) - 1500ms);
    animation-delay: calc((1200ms) - 1500ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--2 {
    -webkit-transform: rotateZ(45deg);
    -ms-transform: rotate(45deg);
    transform: rotateZ(45deg)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--2 .qodef-circle--1 {
    -webkit-animation-delay: calc((300ms) - 1400ms);
    animation-delay: calc((300ms) - 1400ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--2 .qodef-circle--2 {
    -webkit-animation-delay: calc((600ms) - 1400ms);
    animation-delay: calc((600ms) - 1400ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--2 .qodef-circle--3 {
    -webkit-animation-delay: calc((900ms) - 1400ms);
    animation-delay: calc((900ms) - 1400ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--2 .qodef-circle--4 {
    -webkit-animation-delay: calc((1200ms) - 1400ms);
    animation-delay: calc((1200ms) - 1400ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--3 {
    -webkit-transform: rotateZ(90deg);
    -ms-transform: rotate(90deg);
    transform: rotateZ(90deg)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--3 .qodef-circle--1 {
    -webkit-animation-delay: calc((300ms) - 1300ms);
    animation-delay: calc((300ms) - 1300ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--3 .qodef-circle--2 {
    -webkit-animation-delay: calc((600ms) - 1300ms);
    animation-delay: calc((600ms) - 1300ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--3 .qodef-circle--3 {
    -webkit-animation-delay: calc((900ms) - 1300ms);
    animation-delay: calc((900ms) - 1300ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--3 .qodef-circle--4 {
    -webkit-animation-delay: calc((1200ms) - 1300ms);
    animation-delay: calc((1200ms) - 1300ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-e-circle {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: currentColor;
    border-radius: 100%;
    -webkit-animation: qode-bounce-delay 1.2s infinite ease-in-out both;
    animation: qode-bounce-delay 1.2s infinite ease-in-out both
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-e-circle.qodef-circle--1 {
    top: 0;
    left: 0
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-e-circle.qodef-circle--2 {
    top: 0;
    right: 0
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-e-circle.qodef-circle--3 {
    right: 0;
    bottom: 0
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-e-circle.qodef-circle--4 {
    left: 0;
    bottom: 0
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion {
    position: relative;
    width: 30px;
    height: 30px;
    margin: -45px 0 0 -30px
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion-item {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 40px;
    width: 40px;
    background: currentColor;
    border-radius: 50%
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion-item.qodef-fusion--1 {
    top: 0;
    left: 50%;
    -webkit-animation: qode-fussion-ball-1 1s 0s ease infinite;
    animation: qode-fussion-ball-1 1s 0s ease infinite;
    z-index: 1
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion-item.qodef-fusion--2 {
    top: 50%;
    left: 100%;
    -webkit-animation: qode-fussion-ball-2 1s 0s ease infinite;
    animation: qode-fussion-ball-2 1s 0s ease infinite;
    z-index: 2
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion-item.qodef-fusion--3 {
    top: 100%;
    left: 50%;
    -webkit-animation: qode-fussion-ball-3 1s 0s ease infinite;
    animation: qode-fussion-ball-3 1s 0s ease infinite;
    z-index: 1
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion-item.qodef-fusion--4 {
    top: 50%;
    left: 0;
    -webkit-animation: qode-fussion-ball-4 1s 0s ease infinite;
    animation: qode-fussion-ball-4 1s 0s ease infinite;
    z-index: 2
}

#qodef-page-spinner.qodef-layout--lines .qodef-m-lines {
    width: 60px;
    height: 60px;
    margin: 0 0 0 -30px
}

#qodef-page-spinner.qodef-layout--lines .qodef-m-line.qodef-line--1 {
    position: absolute;
    width: 60px;
    height: 4px;
    margin-left: 10px;
    background-color: currentColor;
    -webkit-animation: qode-lines-spin 1.5s infinite ease calc((100ms) - 100ms);
    animation: qode-lines-spin 1.5s infinite ease calc((100ms) - 100ms)
}

#qodef-page-spinner.qodef-layout--lines .qodef-m-line.qodef-line--2 {
    position: absolute;
    width: 60px;
    height: 4px;
    margin-left: 10px;
    background-color: currentColor;
    -webkit-animation: qode-lines-spin 1.5s infinite ease calc((200ms) - 100ms);
    animation: qode-lines-spin 1.5s infinite ease calc((200ms) - 100ms)
}

#qodef-page-spinner.qodef-layout--lines .qodef-m-line.qodef-line--3 {
    position: absolute;
    width: 60px;
    height: 4px;
    margin-left: 10px;
    background-color: currentColor;
    -webkit-animation: qode-lines-spin 1.5s infinite ease calc((300ms) - 100ms);
    animation: qode-lines-spin 1.5s infinite ease calc((300ms) - 100ms)
}

#qodef-page-spinner.qodef-layout--lines .qodef-m-line.qodef-line--4 {
    position: absolute;
    width: 60px;
    height: 4px;
    margin-left: 10px;
    background-color: currentColor;
    -webkit-animation: qode-lines-spin 1.5s infinite ease calc((400ms) - 100ms);
    animation: qode-lines-spin 1.5s infinite ease calc((400ms) - 100ms)
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis {
    position: relative;
    height: 50px;
    width: 50px;
    margin: -25px 0 0 -25px;
    -webkit-animation: qode-mitosis-invert 2s infinite steps(2, end);
    animation: qode-mitosis-invert 2s infinite steps(2, end)
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: currentColor;
    border-radius: 50%
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis-item.qodef-mitosis--1 {
    -webkit-animation: qode-mitosis-ball-1 1s infinite alternate linear;
    animation: qode-mitosis-ball-1 1s infinite alternate linear
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis-item.qodef-mitosis--2 {
    -webkit-animation: qode-mitosis-ball-2 1s infinite alternate linear;
    animation: qode-mitosis-ball-2 1s infinite alternate linear
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis-item.qodef-mitosis--3 {
    -webkit-animation: qode-mitosis-ball-3 1s infinite alternate linear;
    animation: qode-mitosis-ball-3 1s infinite alternate linear
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis-item.qodef-mitosis--4 {
    -webkit-animation: qode-mitosis-ball-4 1s infinite alternate linear;
    animation: qode-mitosis-ball-4 1s infinite alternate linear
}

#qodef-page-spinner.qodef-layout--plamen .qodef-m-spinner {
    width: 100%;
    height: 100%
}

#qodef-page-spinner.qodef-layout--plamen .qodef-m-plamen {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #000;
    background-image: url(../../assets/img/preloader-background.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.qodef-browser--edge #qodef-page-spinner.qodef-layout--plamen .qodef-m-plamen {
    background-image: none
}

#qodef-page-spinner.qodef-layout--plamen .qodef-m-plamen-smoke {
    position: absolute;
    bottom: 0;
}

#qodef-page-spinner.qodef-layout--plamen .qodef-m-plamen-smoke video {
    mix-blend-mode: screen
}
