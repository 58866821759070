@import '../../assets/scss';

.header-container {
    width: 100vw;
    display: flex;

    .header-left {
        flex: 1;

        .logo {
            max-height: 8rem;

            @media only screen and (max-width:680px) {
                & {
                    max-height: 4rem;
                }
            }
        }
    }

    .header-right {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        padding: 5px;

        .link {
            padding: 12px;

            .social-icon {
                color: $gray-light;
                font-size: 2rem;

                &.svg {
                    position: relative;
                    height: 2.8rem;
                    top: -5px;
                    right: -5px;
                }
            }
        }

        .wallet-connect-button {
            margin: 8px;
            padding: 8px 20px;
            border: none;
            border-radius: 12px;
            background-color: $gray-lighter;
            color: $gray-dark;
            font-size: 18px;
            min-width: 10rem;
            cursor: pointer;

            &.connected {
                background-color: $brand-red;
                color: $white;
            }
        }
    }
}