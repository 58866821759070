@import url(https://fonts.googleapis.com/css?family=Josefin+Sans%3A300%2C400%2C500%2C600%2C700%7CMontserrat%3A300%2C400%2C500%2C600%2C700%7CGreat+Vibes%3A300%2C400%2C500%2C600%2C700&#038;subset=latin-ext&#038;);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans%3A300%2C400%2C500%2C600%2C700%7CMontserrat%3A300%2C400%2C500%2C600%2C700%7CGreat+Vibes%3A300%2C400%2C500%2C600%2C700&#038;subset=latin-ext&#038;);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans%3A300%2C400%2C500%2C600%2C700%7CMontserrat%3A300%2C400%2C500%2C600%2C700%7CGreat+Vibes%3A300%2C400%2C500%2C600%2C700&#038;subset=latin-ext&#038;);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans%3A300%2C400%2C500%2C600%2C700%7CMontserrat%3A300%2C400%2C500%2C600%2C700%7CGreat+Vibes%3A300%2C400%2C500%2C600%2C700&#038;subset=latin-ext&#038;);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body {
  font-size: 16px;
  line-height: 130%;
  font-family: "Josefin Sans", sans-serif;
  color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  color: #c88a5a;
  line-height: 140%;
}

#qodef-page-spinner {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

#qodef-page-spinner .qodef-m-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #c88a5a;
  background-color: #fff;
}

#qodef-page-spinner .qodef-m-spinner {
  position: relative;
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom {
  position: relative;
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -50px;
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--1 {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50%;
  width: 50%;
  z-index: 1;
  animation: qode-atom-shrink 4.5s infinite linear;
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--1:before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  height: 100%;
  width: 100%;
  background: currentColor;
  border-radius: 50%;
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--2,
#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--3,
#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--4 {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--2:before,
#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--3:before,
#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--4:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  margin: 0 auto;
  background: currentColor;
  border-radius: 50%;
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--2 {
  animation: qode-atom-zindex 1.5s 0.75s infinite steps(2, end);
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--2:before {
  transform: rotate(0);
  animation: qode-atom-postion 1.5s infinite ease, qode-atom-size 1.5s -1.125s infinite ease;
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--3 {
  transform: rotate(120deg);
  animation: qode-atom-zindex 1.5s -0.25s infinite steps(2, end);
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--3:before {
  animation: qode-atom-postion 1.5s -1s infinite ease, qode-atom-size 1.5s -0.75s infinite ease;
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--4 {
  transform: rotate(240deg);
  animation: qode-atom-zindex 1.5s 0.25s infinite steps(2, end);
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--4:before {
  animation: qode-atom-postion 1.5s -0.5s infinite ease, qode-atom-size 1.5s -125ms infinite ease;
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock {
  position: relative;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  animation: qode-clock 8s infinite steps(8);
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item {
  position: absolute;
  height: 50px;
  width: 50px;
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item:before {
  content: "";
  position: absolute;
  height: 18.31501832px;
  width: 18.31501832px;
  background: currentColor;
  border-radius: 50%;
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item.qodef-clock--1 {
  transform: rotate(-135deg);
  animation: qode-clock-1 1s ease-in infinite;
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item.qodef-clock--2 {
  transform: rotate(-90deg);
  animation: qode-clock-2 1s ease-in infinite;
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item.qodef-clock--3 {
  transform: rotate(-45deg);
  animation: qode-clock-3 1s ease-in infinite;
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item.qodef-clock--4 {
  animation: qode-clock-4 1s ease-in infinite;
}

#qodef-page-spinner.qodef-layout--cube .qodef-m-cube {
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  background-color: currentColor;
  animation: qode-cube 1.2s infinite ease-in-out;
}

#qodef-page-spinner.qodef-layout--double-pulse .qodef-m-pulses {
  position: relative;
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
}

#qodef-page-spinner.qodef-layout--double-pulse .qodef-m-pulse {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0.6;
  animation: qode-double-pulse 2s infinite ease-in-out;
}

#qodef-page-spinner.qodef-layout--double-pulse .qodef-m-pulse.qodef-pulse--2 {
  animation-delay: -1s;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-five-rotating-circles {
  position: relative;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item {
  position: absolute;
  width: 100%;
  height: 100%;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--1 .qodef-circle--2 {
  animation-delay: -900ms;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--1 .qodef-circle--3 {
  animation-delay: -600ms;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--1 .qodef-circle--4 {
  animation-delay: -300ms;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--2 {
  transform: rotateZ(45deg);
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--2 .qodef-circle--1 {
  animation-delay: -1100ms;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--2 .qodef-circle--2 {
  animation-delay: -800ms;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--2 .qodef-circle--3 {
  animation-delay: -500ms;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--2 .qodef-circle--4 {
  animation-delay: -200ms;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--3 {
  transform: rotateZ(90deg);
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--3 .qodef-circle--1 {
  animation-delay: -1000ms;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--3 .qodef-circle--2 {
  animation-delay: -700ms;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--3 .qodef-circle--3 {
  animation-delay: -400ms;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--3 .qodef-circle--4 {
  animation-delay: -100ms;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-e-circle {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: currentColor;
  border-radius: 100%;
  animation: qode-bounce-delay 1.2s infinite ease-in-out both;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-e-circle.qodef-circle--1 {
  top: 0;
  left: 0;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-e-circle.qodef-circle--2 {
  top: 0;
  right: 0;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-e-circle.qodef-circle--3 {
  right: 0;
  bottom: 0;
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-e-circle.qodef-circle--4 {
  left: 0;
  bottom: 0;
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion {
  position: relative;
  width: 30px;
  height: 30px;
  margin: -45px 0 0 -30px;
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion-item {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 40px;
  width: 40px;
  background: currentColor;
  border-radius: 50%;
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion-item.qodef-fusion--1 {
  top: 0;
  left: 50%;
  animation: qode-fussion-ball-1 1s 0s ease infinite;
  z-index: 1;
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion-item.qodef-fusion--2 {
  top: 50%;
  left: 100%;
  animation: qode-fussion-ball-2 1s 0s ease infinite;
  z-index: 2;
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion-item.qodef-fusion--3 {
  top: 100%;
  left: 50%;
  animation: qode-fussion-ball-3 1s 0s ease infinite;
  z-index: 1;
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion-item.qodef-fusion--4 {
  top: 50%;
  left: 0;
  animation: qode-fussion-ball-4 1s 0s ease infinite;
  z-index: 2;
}

#qodef-page-spinner.qodef-layout--lines .qodef-m-lines {
  width: 60px;
  height: 60px;
  margin: 0 0 0 -30px;
}

#qodef-page-spinner.qodef-layout--lines .qodef-m-line.qodef-line--1 {
  position: absolute;
  width: 60px;
  height: 4px;
  margin-left: 10px;
  background-color: currentColor;
  animation: qode-lines-spin 1.5s infinite ease 0ms;
}

#qodef-page-spinner.qodef-layout--lines .qodef-m-line.qodef-line--2 {
  position: absolute;
  width: 60px;
  height: 4px;
  margin-left: 10px;
  background-color: currentColor;
  animation: qode-lines-spin 1.5s infinite ease 100ms;
}

#qodef-page-spinner.qodef-layout--lines .qodef-m-line.qodef-line--3 {
  position: absolute;
  width: 60px;
  height: 4px;
  margin-left: 10px;
  background-color: currentColor;
  animation: qode-lines-spin 1.5s infinite ease 200ms;
}

#qodef-page-spinner.qodef-layout--lines .qodef-m-line.qodef-line--4 {
  position: absolute;
  width: 60px;
  height: 4px;
  margin-left: 10px;
  background-color: currentColor;
  animation: qode-lines-spin 1.5s infinite ease 300ms;
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis {
  position: relative;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  animation: qode-mitosis-invert 2s infinite steps(2, end);
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: currentColor;
  border-radius: 50%;
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis-item.qodef-mitosis--1 {
  animation: qode-mitosis-ball-1 1s infinite alternate linear;
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis-item.qodef-mitosis--2 {
  animation: qode-mitosis-ball-2 1s infinite alternate linear;
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis-item.qodef-mitosis--3 {
  animation: qode-mitosis-ball-3 1s infinite alternate linear;
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis-item.qodef-mitosis--4 {
  animation: qode-mitosis-ball-4 1s infinite alternate linear;
}

#qodef-page-spinner.qodef-layout--plamen .qodef-m-spinner {
  width: 100%;
  height: 100%;
}

#qodef-page-spinner.qodef-layout--plamen .qodef-m-plamen {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  background-image: url(/static/media/preloader-background.1665a850.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.qodef-browser--edge #qodef-page-spinner.qodef-layout--plamen .qodef-m-plamen {
  background-image: none;
}

#qodef-page-spinner.qodef-layout--plamen .qodef-m-plamen-smoke {
  position: absolute;
  bottom: 0;
}

#qodef-page-spinner.qodef-layout--plamen .qodef-m-plamen-smoke video {
  mix-blend-mode: screen;
}
html, body {
  font-size: 16px;
  line-height: 130%;
  font-family: "Josefin Sans", sans-serif;
  color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  color: #c88a5a;
  line-height: 140%;
}

.header-container {
  width: 100vw;
  display: flex;
}
.header-container .header-left {
  flex: 1 1;
}
.header-container .header-left .logo {
  max-height: 8rem;
}
@media only screen and (max-width: 680px) {
  .header-container .header-left .logo {
    max-height: 4rem;
  }
}
.header-container .header-right {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
  padding: 5px;
}
.header-container .header-right .link {
  padding: 12px;
}
.header-container .header-right .link .social-icon {
  color: rgb(195, 195, 195);
  font-size: 2rem;
}
.header-container .header-right .link .social-icon.svg {
  position: relative;
  height: 2.8rem;
  top: -5px;
  right: -5px;
}
.header-container .header-right .wallet-connect-button {
  margin: 8px;
  padding: 8px 20px;
  border: none;
  border-radius: 12px;
  background-color: rgb(235, 235, 235);
  color: rgb(80, 80, 80);
  font-size: 18px;
  min-width: 10rem;
  cursor: pointer;
}
.header-container .header-right .wallet-connect-button.connected {
  background-color: rgb(226, 32, 32);
  color: #fff;
}
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
html, body {
  font-size: 16px;
  line-height: 130%;
  font-family: "Josefin Sans", sans-serif;
  color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  color: #c88a5a;
  line-height: 140%;
}

.team-member-item {
  position: relative;
  width: 25%;
}
@media only screen and (max-width: 780px) {
  .team-member-item {
    width: 100%;
    margin: 0 0 50px 0;
  }
}
.team-member-item .team-member-image {
  border-radius: 50%;
  border: 1px solid #c88a5a;
  padding: 10px;
  overflow: hidden;
  line-height: 0;
  margin: 0 auto;
  transition: opacity 0.5s ease-in-out;
}
.team-member-item .team-member-image img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.team-member-item h2 {
  color: #c88a5a;
}
.team-member-item .twitter a {
  font-size: 1.2rem;
  font-weight: 600;
  color: rgb(29, 155, 240);
  text-decoration: none;
}
.team-member-item .twitter a:hover {
  color: #0a629d;
}
.team-member-item .twitter .social-icon {
  position: relative;
  top: 5px;
}
.team-member-item .description {
  color: rgb(235, 235, 235);
  font-size: 1.2rem;
  line-height: 150%;
  text-align: left;
}
html, body {
  font-size: 16px;
  line-height: 130%;
  font-family: "Josefin Sans", sans-serif;
  color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  color: #c88a5a;
  line-height: 140%;
}

* {
  box-sizing: border-box;
}

body, html {
  height: 100%;
  margin: 0;
}

main.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.main-app {
  width: 100vw;
  height: 100vh;
}
.main-app section.content-container {
  padding: 100px 0 150px 0;
  text-align: center;
}
@media only screen and (max-width: 680px) {
  .main-app section.content-container {
    padding: 50px 0 50px 0;
  }
}
.main-app section.content-container .section-title {
  font-family: "Great Vibes", serif;
  font-size: 4rem;
  font-weight: 300;
  color: #fff;
}
.main-app section.content-container.section-mint {
  min-height: 400px;
}
.main-app section.content-container.section-mint .mint-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.65);
  border: solid 1px #c88a5a;
  border-radius: 1rem;
  min-height: 400px;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 680px) {
  .main-app section.content-container.section-mint .mint-container {
    width: 90%;
  }
}
.main-app section.content-container.section-mint .mint-container .tx-receipt {
  margin: 1rem 0;
}
.main-app section.content-container.section-mint .mint-container .tx-receipt a {
  font-size: 1rem;
  color: #c88a5a;
  text-decoration: none;
}
.main-app section.content-container.section-mint .mint-container .mint-loading {
  font-family: "Great Vibes", serif;
  font-size: 3rem;
  color: #c88a5a;
}
.main-app section.content-container.section-mint .mint-container .mint-count {
  font-family: "Great Vibes", serif;
  font-size: 3rem;
  color: #c88a5a;
  margin: 50px 0;
}
.main-app section.content-container.section-team .team {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10rem;
  width: 100%;
}
@media only screen and (max-width: 780px) {
  .main-app section.content-container.section-team .team {
    flex-direction: column;
    padding: 0 4rem;
  }
}
.main-app section.content-container.section-footer .contract-address,
.main-app section.content-container.section-footer .contract-address a {
  color: #c88a5a;
  text-decoration: none;
}

.form-row {
  display: block;
  margin: 1rem;
}

.input-mint-number {
  padding: 12px;
  font-size: 18px;
}

.cta-button {
  padding: 15px;
  border: none;
  border-radius: 12px;
  min-width: 250px;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.connect-wallet-button {
  background: rgb(226, 32, 32);
}

.mint-nft-button {
  background: rgb(226, 32, 32);
}
.mint-nft-button:disabled {
  cursor: default;
  opacity: 0.35;
}

.error-message-container {
  margin: 1rem 0;
  width: 100vw;
  padding: 1rem 0;
  background: rgb(164, 10, 10);
  text-align: center;
}
.error-message-container .error-message {
  color: #fff;
  font-weight: 700;
}
